/**
 * Shop JS
 */

'use strict';

import Swiper from 'swiper';
import ShopSildeShow from './sildeshow';

export default class ShopPage {
    constructor() {
        this.body = document.querySelector('body');
        this.shopSildeShow;
    }

    /**
     * init 
     */
    init() {
        if (this.body.classList.contains('page-template-page-shop')) {
            if (!this.shopSildeShow) this.shopSildeShow = new ShopSildeShow();
            this.shopSildeShow.init();

            var swiper = new Swiper(".shop-carousel-center", {
                spaceBetween: 16,
                slidesPerView: 'auto',
                centeredSlides: true,
                loop: true,
                slidesPerGroup: 1,
                paginationClickable: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
        }
    }

    /**
     * Delete
     */

    shopSildeShowDestroy() {
        if (this.shopSildeShow) { this.shopSildeShow.allDestroy(); this.shopSildeShow = null; }
    }

    static createInstance() {
        var object = new ShopPage();
        return object;
    }

    static getInstance() {
        let object;
        if (!ShopPage.shopSildeShow) {
            object = ShopPage.createInstance();
            ShopPage.shopSildeShow = object;
        }
        return ShopPage.shopSildeShow;
    }

}
