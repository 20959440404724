/**
 * Group Tours JS
 */

'use strict';

// import HoverSildeShow from './hoversildeshow';
import GroupTourSildeShow from './carousel';
import Popup2021 from './popup2021';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import CustomBodymovin from './bodymovin';

export default class GroupTours {
    constructor() {
        this.body = document.querySelector('body');
        this.groupTourSildeShow;
        this.iconAnimation = [...document.querySelectorAll('.icon-animation')];
        this.iconJsons = [...document.querySelectorAll('[data-json-animation]')];
        this.iconSlugNames = ['phone', 'inquiry', 'email'];
        // this.init();
    }

    /**
     * init 
     */
    init() {
        if (this.body.classList.contains('page-template-page-riverlodge')) {
            if (!this.groupTourSildeShow) this.groupTourSildeShow = new GroupTourSildeShow();
            this.groupTourSildeShow.init();
        }

        this.iconSlugNames.forEach((item, i) => {
            let url = this.iconJsons[i].dataset.jsonAnimation;
            this.setting(url, item);
        });
    }

    setting(url, item) {
        fetch(url)
            .then(
                response => {
                    if (response.status !== 200) {
                        console.log('Looks like there was a problem. Status Code: ' +
                            response.status);
                        return;
                    }
                    response.json().then(data => {
                        this.LottieSet(data, item, url);
                        // this.BMSet(data, item);
                    });
                }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    LottieSet(data, item, url) {
        let badge = document.getElementById('icon--' + item);

        let obj = lottie.loadAnimation({
            container: badge,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: url,
            rendererSettings: {
                progressiveLoad: false
            },
            animationData: this.data
        });


        obj.play();
    }


    /**
     * Delete
     */
    changeNoticeBar() {
        document.querySelector('.notice-bar a').style.backgroundColor = '';
    }

    homeSildeShowDestroy() {
        if (this.groupTourSildeShow) { this.groupTourSildeShow.allDestroy(); this.groupTourSildeShow = null; }
    }

    static createInstance() {
        var object = new GroupTours();
        return object;
    }

    static getInstance() {
        let object;
        if (!GroupTours.groupTourSildeShow) {
            object = GroupTours.createInstance();
            GroupTours.groupTourSildeShow = object;
        }
        return GroupTours.groupTourSildeShow;
    }

}
