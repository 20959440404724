/**
 * carouselCover Silde Show JS
 */

'use strict';

import Swiper from 'swiper';
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators"; //for indicators
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"; //if you want to use GSAP
import {
    TweenMax,
} from 'gsap';

export default class SildeShow {

    constructor() {
        this.carouselCover = document.querySelector('.carousel-cover__container');
        this.indicator = document.querySelector('.swiper-pagination-bullet-active__indicator');
        this.carouselCoverNav = [...document.querySelectorAll('.carousel-cover-pagination-bullet')];
        this.carouselCoverNavBullet = null;
        this.carouselCoverID = null;
        this.carouselCoverSwiper = null;
    }

    init() {
        if (this.carouselCover) this.coverCarousel();
        if (this.carousel) this.carouselInit();
        if (this.reviews) this.reviewsInit();
    }

    /**
     * carouselCover Main SildeShow Init
     * ==============================================
     */
    coverCarousel() {
        if (this.carouselCoverSwiper) return false;
        this.carouselCoverSwiper = new Swiper(this.carouselCover, {
            speed: 1000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            loop: true,
            autoplay: {
                delay: 6000,
                waitForTransition: false,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: false,
                renderBullet: (index, className) => {
                    return '<div class="' + className + '">' + this.carouselCoverNav[index].innerHTML + '</div>';
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        this.carouselCoverNavBullet = [...document.querySelectorAll('.swiper-pagination-bullet')];
        this.carouselCoverID = requestAnimationFrame(this.checkingIndicator.bind(this));
    }

    checkingIndicator() {
        this.carouselCover = document.querySelector('.carousel-cover__container');
        if (!this.carouselCover) {
            cancelAnimationFrame(this.carouselCoverID);
            return false;
        }
        if (this.carouselCoverNavBullet) {
            let active = document.querySelector('.swiper-pagination-bullet-active');
            if (!active) return false;
            this.indicator.style.width = this.carouselCoverNavBullet[0].offsetWidth + 'px';
            this.indicator.style.left = active.offsetLeft + 'px';
        } else {
            this.carouselCoverNavBullet = [...document.querySelectorAll('.swiper-pagination-bullet')];
        }
        requestAnimationFrame(this.checkingIndicator.bind(this));
    }


    allDestroy() {
        if (this.carouselCoverSwiper) this.carouselCoverSwiper.destroy();
        this.carouselCoverSwiper = null;
        cancelAnimationFrame(this.carouselCoverID);
    }

}