/**
 * Homepage JS
 */

'use strict';

// import HoverSildeShow from './hoversildeshow';
import HomeSildeShow from './sildeshow';
import Popup2021 from './popup2021';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default class Home {
    constructor() {
        this.body = document.querySelector('body');
        this.newsItems = [...document.querySelectorAll('.news__item:not(.news__item--featured)')];
        // this.hoverSildeShow;
        // this.popup = new Popup2021();
        this.homeSildeShow;
    }

    /**
     * init 
     */
    init() {
        if (this.body.classList.contains('home')) {
            // if (this.hoverSildeShow) this.hoverSildeShow = null;
            // this.hoverSildeShow = new HoverSildeShow(null, false);
            // this.hoverSildeShow.init();
            if (!this.homeSildeShow) this.homeSildeShow = new HomeSildeShow();
            this.homeSildeShow.init();
        }
    }

    /**
     * Delete
     */
    changeNoticeBar() {
        document.querySelector('.notice-bar a').style.backgroundColor = '';
    }

    homeSildeShowDestroy() {
        if (this.homeSildeShow) { this.homeSildeShow.allDestroy(); this.homeSildeShow = null; }
    }

    static createInstance() {
        var object = new Home();
        return object;
    }

    static getInstance() {
        let object;
        if (!Home.homeSildeShow) {
            object = Home.createInstance();
            Home.homeSildeShow = object;
        }
        return Home.homeSildeShow;
    }


}
