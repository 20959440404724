/**
 * Home Silde Show JS
 */

'use strict';

import Swiper from 'swiper';
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators"; //for indicators
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"; //if you want to use GSAP
import {
    TweenMax,
} from 'gsap';

export default class SildeShow {

    constructor() {
        this.homecover = document.querySelector('.home-cover__container');
        this.indicator = document.querySelector('.swiper-pagination-bullet-active__indicator');
        this.homecoverNav = [...document.querySelectorAll('.home-cover-pagination-bullet')];
        this.homecoverNavBullet = null;
        this.homeID = null;
        this.homeSwiper = null;

        this.carousel = document.querySelector('.carousel');
        this.carouselNav = [...document.querySelectorAll('.carousel__button-label')];
        this.carouselSpeed = 500;
        this.carouselDelay = 10000;
        this.progressNav = 0;
        this.carouselMaxNum = this.carouselNav.length;
        this.carouselSwiper = null;

        this.reviews = document.querySelector('.reviews-wrapper');
        this.reviewsSwiper = null;
        this.reviewsSpeed = 400;
        this.reviewsDelay = 9000;

    }

    init() {
        if (this.homecover) this.homecoverInit();
        if (this.carousel) this.carouselInit();
        if (this.reviews) this.reviewsInit();
    }

    /**
     * Home Main SildeShow Init
     * ==============================================
     */
    homecoverInit() {
        if (this.homeSwiper) return false;
        this.homeSwiper = new Swiper(this.homecover, {
            speed: 1000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            loop: true,
            autoplay: {
                delay: 6000,
                waitForTransition: false,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: false,
                renderBullet: (index, className) => {
                    return '<div class="' + className + '">' + this.homecoverNav[index].innerHTML + '</div>';
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        this.homecoverNavBullet = [...document.querySelectorAll('.swiper-pagination-bullet')];
        this.homeID = requestAnimationFrame(this.checkingIndicator.bind(this));
    }

    checkingIndicator() {
        this.homecover = document.querySelector('.home-cover__container');
        if (!this.homecover) {
            cancelAnimationFrame(this.homeID);
            return false;
        }
        if (this.homecoverNavBullet) {
            let active = document.querySelector('.swiper-pagination-bullet-active');
            if (!active) return false;
            this.indicator.style.width = this.homecoverNavBullet[0].offsetWidth + 'px';
            this.indicator.style.left = active.offsetLeft + 'px';
        } else {
            this.homecoverNavBullet = [...document.querySelectorAll('.swiper-pagination-bullet')];
        }
        requestAnimationFrame(this.checkingIndicator.bind(this));
    }


    /**
     * Home Carousel Init
     * ==============================================
     */
    carouselInit() {
        if (this.carouselSwiper) return false;
        let previousIndex = 0;
        let currentIndex = 0;
        let carouselSlideItems = null;
        this.carouselSwiper = new Swiper(this.carousel, {
            init: false,
            loop: true,
            initialSlide: 0,
            autoplay: {
                delay: this.carouselDelay,
                waitForTransition: false,
                disableOnInteraction: false
            },
            pagination: {
                el: '.carousel__nav',
                clickable: true,
                bulletClass: 'carousel__button',
                bulletActiveClass: 'carousel__button--active',
                renderBullet: (index, className) => {
                    return `<button class="${className}" data-index="${index}"><span class="carousel__button-label u-hide-mobile">${this.carouselNav[index].textContent}</span><span class="carousel__button-timer carousel__button-timer--bg"></span></button>`;
                },
            },
        });
        this.carouselSwiper.init();
        carouselSlideItems = [...document.querySelectorAll('.carousel-slide')];
        currentIndex = this.carouselSwiper.activeIndex;
        this.slideChangeNavHandle(1);
        this.carouselSwiper.on('slideChange', () => {
            let item = carouselSlideItems[currentIndex];
            let prevItem = carouselSlideItems[previousIndex];
            item.classList.add('carousel-slide-prev');
            prevItem.classList.remove('carousel-slide-prev');
            currentIndex = parseInt(this.carouselSwiper.activeIndex);
            previousIndex = parseInt(this.carouselSwiper.previousIndex);
            this.slideChangeNavHandle(this.carouselSwiper.activeIndex);
        });

    }


    slideChangeNavHandle(cnum) {
        if (this.tweenAnimation) {
            this.tweenAnimation.kill();
            TweenMax.set(this.prevNav, {
                clearProps: "all"
            });
        }
        let carousel = document.querySelector('.carousel');
        if (!carousel) return false;
        if (this.progressNav) {
            clearInterval(this.progressNav);
            this.prevNav.style.width = 'auto';
        }
        let autoplay, width = 0;
        let elem = document.querySelector('.carousel__button--active');
        let autoplayTime = this.carouselDelay / 1000;

        if (elem) {
            let elemBg = elem.querySelector('.carousel__button-timer--bg');
            this.tweenAnimation = new TweenMax.fromTo(elemBg, autoplayTime, {
                width: "0%"
            }, {
                width: "100%",
                ease: "none",
                onComplete: function () {
                    TweenMax.set(elemBg, {
                        clearProps: "all"
                    });
                }
            });

            this.prevNav = elemBg;
        }
    }




    /**
     * Reviews Init
     * ==============================================
     */
    reviewsInit() {

        if (this.reviewsSwiper) return false;
        this.reviewsSwiper = new Swiper(this.reviews, {
            speed: this.reviewsSpeed,
            loop: true,
            autoplay: {
                delay: this.reviewsDelay,
                disableOnInteraction: false,
                waitForTransition: false
            },
            navigation: {
                nextEl: '.reviews-button-next',
                prevEl: '.reviews-button-prev',
            },
        });
        this.slideChangeProgressReviewsHandle(1);
        this.reviewsSwiper.on('slideChange', () => {
            this.slideChangeProgressReviewsHandle(this.reviewsSwiper.activeIndex);
        });
    }

    slideChangeProgressReviewsHandle(cnum) {

        let autoplayTime = (this.reviewsDelay) / 1000;
        let reviewsProgressbar = document.querySelector('#reviews-progressbar');
        let tweenAnimation = new TweenMax.fromTo(reviewsProgressbar, autoplayTime, {
            width: "0%"
        }, {
            width: "100%",
            ease: "none",
            onComplete: function () {
                TweenMax.set(reviewsProgressbar, {
                    clearProps: "all"
                });
            }
        });
    }

    allDestroy() {
        if (this.homeSwiper) this.homeSwiper.destroy();
        this.homeSwiper = null;
        cancelAnimationFrame(this.homeID);
        if (this.carouselSwiper) this.carouselSwiper.destroy();
        this.carouselSwiper = null;
        if (this.reviewsSwiper) this.reviewsSwiper.destroy();
        this.reviewsSwiper = null;
    }

}